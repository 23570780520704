import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import BackButtonHeader from "../../components/BackButtonHeader";
import "./projects.scss";

function TopoDNN(): JSX.Element {
  return (
    <div className="project-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eshed Margalit | Topographic DNNs</title>
        <html lang="en" />
      </Helmet>
      <BackButtonHeader title="Topographic DNNs" />

      <div className="project-info">
        <p>
          The visual system is composed of neurons embedded in detailed
          topographic maps. Although the past few years have seen huge advances
          in our ability to predict the activity of neurons in these maps with
          deep neural networks, models of the structure of the maps themselves
          is lacking. I'm using deep convolutional neural networks to better
          understand 1) the role of this functional architecture and 2) how it
          might develop in the brain.
        </p>
      </div>

      <h3>Read our paper: A unifying framework for functional organization in early and higher ventral visual cortex</h3>
      <a
        href="https://www.cell.com/neuron/abstract/S0896-6273(24)00279-4"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
          Margalit, E., Lee, H., Finzi, D., DiCarlo, J. J., Grill-Spector, K., & Yamins, D. L. (2024). A unifying framework for functional organization in early and higher ventral visual cortex. Neuron.
          </div>
        </div>
      </a>

      <StaticImage
        src="../../images/margalit_2024_summary_figure.jpeg"
        alt="Image from Stanford press release."
        placeholder="tracedSVG"
        height={750}
        width={1000}
      />

      <hr />

      <h3>COSYNE Poster</h3>
      <StaticImage
        src="../../images/posters/margalit_cosyne_poster_2020.png"
        alt="Poster presented at COSYNE 2020"
        placeholder="tracedSVG"
        height={500}
        width={1000}
      />

      <h3>Read the preprint</h3>
      <a
        href="https://www.biorxiv.org/content/10.1101/2023.05.18.541361v1"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
            Margalit, E., Lee, H., Finzi, D., Dicarlo, J.J., Grill-Spector, K.,
            and Yamins, D.L.K. (2023). A Unifying Principle for the Functional
            Organization of Visual Cortex. bioRxiv doi:
            10.1101/2023.05.18.541361
          </div>
        </div>
      </a>

      <h3>Read our earlier preprint</h3>
      <a
        href="https://www.biorxiv.org/content/10.1101/2020.07.09.185116v1"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
            Lee, H., Margalit, E., Jozwik, K. M., Cohen, M. A., Kanwisher, N.,
            Yamins, D. L., & DiCarlo, J. J. (2020). Topographic deep artificial
            neural networks reproduce the hallmarks of the primate inferior
            temporal cortex face processing network. bioRxiv.
          </div>
        </div>
      </a>
    </div>
  );
}

export default TopoDNN;
